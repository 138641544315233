import React from 'react';

const EnableOwnerChangeButton = ({ onClick }) => {
  return (
    <button variant="primary" className="ownerChangeButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>👤</span>
      Change Owner
    </button>
  );
};

export default EnableOwnerChangeButton;