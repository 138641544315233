import React from 'react';

const EnableChangeTimeZoneButton = ({ onClick }) => {
  return (
    <button variant="primary" className="timeZoneChangeButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>🌐</span>
      Change Time Zone
    </button>
  );
};

export default EnableChangeTimeZoneButton;