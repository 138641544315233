import React from 'react';

const EnableInvoiceButton = ({ onClick }) => {
  return (
    <button variant="primary" className="invoiceButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>📄</span>
      Add Annual Invoice
    </button>
  );
};

export default EnableInvoiceButton;