import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EnableExtendTrialButton = ({ onClick }) => {
  return (
    <button variant="primary" className="extendTrialButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>⏳</span>
      Extend Trial
    </button>
  );
};

export default EnableExtendTrialButton;