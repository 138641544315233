import React from 'react';

const EnableLCRButton = ({ onClick }) => {
  return (
    <button variant="primary" className="lcrButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>&#128222;</span>
      Enable Live Call Routing
    </button>
  );
};

export default EnableLCRButton;