import React from 'react';

const EnablePlanChangeButton = ({ onClick }) => {
  return (
    <button variant="primary" className="planChangeButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>📦</span>
      Change Plan
    </button>
  );
};

export default EnablePlanChangeButton;