import React from 'react';

const EnableCSChangeButton = ({ onClick }) => {
  return (
    <button variant="primary" className="csChangeButton" onClick={onClick}>
      <span style={{ marginRight: '8px' }}>👥</span>
      Change Customer Success Person
    </button>
  );
};

export default EnableCSChangeButton;