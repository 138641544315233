import React, { Fragment, useEffect, useState, useRef } from "react";
import { getSignupDetails, getDealFromSubdomain, getDealDetailsFromPipeDealID, getSalesGuyFromOwnerID, getPackages, changePackage, getAdmins, changeOwner, extendTrial, getInvoicePackage, getServiceProviders, getCSPeople, getSalesPeople, addInvoice, getSubscriptionDetails, getSignupDetailsForCust, changeTimeZone, getTimeZones, assignCs, renewInvoice, cancelInvoice, addUsers } from "../fetch";
import { useLocation } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { enableLCR } from '../fetch';
import { useMsal } from "@azure/msal-react";
import EnableLCRButton from './EnableLCRButton';
import EnablePlanChangeButton from "./EnablePlanChange";
import EnableOwnerChangeButton from "./EnableOwnerChange";
import EnableExtendTrialButton from "./EnableExtendButton";
import EnableInvoiceButton from "./EnableInvoiceButton";
import EnableCSChangeButton from "./EnableCSChangeButton";
import EnableChangeTimeZoneButton from "./EnableChangeTimeZone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
/**
 * useMsal is hook that returns the PublicClientApplication instance, 
 * an array of all accounts currently signed in and an inProgress value 
 * that tells you what msal is currently doing. For more, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
 */


export const signupdetails = () => {



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const param1 = queryParams.get('account');
    const history = useHistory();



    var dataT;
    const dealFieldsToShow = [
        'Deal ID',
        'Creator.name',
        'Title',
        'Value',
        'Currency',
        'Added Time',
        'Last Updated Time',
        'Status',
        'Person',
        'Org Name',
        'Annual Fee'
    ];


    const [comments, setComments] = useState('');
    const [todoListData, setTodoListData] = useState([]);
    const [isLoadingData, setisLoadingData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showData, setShowData] = useState(false);
    const [reloaded, setReloaded] = useState(false);
    const [selectedDate_I, setSelectedDate_I] = useState(null);
    const [matchedEmployeeId, setMatchedEmployeeId] = useState('');
    const [userCount, setuserCount] = useState(null);
    const [stakeholderCount, setstakeholderCount] = useState(null);
    const [annualFee, setannualFee] = useState('');
    const [stakeholderFee, setStakeholderFee] = useState('');
    const [stakeholderFee_invoice, setStakeholderFee_invoice] = useState('');
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionPackageId, setSubscriptionPackageId] = useState('');
    const [subscriptionPackageName, setSubscriptionPackageName] = useState('');
    const [userFee, setUserFee] = useState('');
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal_package, setShowModalPackage] = useState(false);
    const [showModal_owner, setShowModalOwner] = useState(false);
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');
    const [text4, setText4] = useState('');
    const formRef = useRef(null);
    const text1Ref = useRef();
    const text2Ref = useRef();
    const text3Ref = useRef();
    const text4Ref = useRef();
    const userCount_ref = useRef();
    const stakeholderCount_ref = useRef();
    const annualFee_ref = useRef();
    const stakeholderFee_ref = useRef();
    const modalRef = useRef(null);
    const modalRef_planChange = useRef(null);
    const modalRef_ownerChange = useRef(null);
    const [availablePackages, setAvailablePackages] = useState([]);
    const [availableOwners, setAvailableOwners] = useState([]);
    const [availableCustomerSuccess, setAvailableCustomerSuccess] = useState([]);
    const [availableSalesPeople, setAvailableSalesPeople] = useState([]);
    const [availableServiceProvider, setAvailableServiceProvider] = useState([]);
    const [invoicePlans, setInvoicePlans] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedOwner, setSelectedOwner] = useState('');
    const [selectedInvoicePlan, setSelectedInvoicePlan] = useState('');
    const [selectedCustomerSuccess, setSelectedCustomerSuccess] = useState('');
    const [selectedServiceProvider, setSelectedServiceProvider] = useState('');
    const [salesPersonContent, setSalesPersonContent] = useState(null);
    const [customerYes, setCustomerYes] = useState(false);
    const [salesPersonEmail, setSalesPersonEmail] = useState('');
    const [dealIdContent, setDealIdContent] = useState(null);
    const [dealDetails, setDealDetails] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [csPerson, setCsPerson] = useState(null);
    const [userCount_main, setuserCount_main] = useState(null);
    const [stakeholderCount_main, setStakeHolderCount_main] = useState(null);
    
    const [availableAdmins, setAvailableAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const { instance, accounts, inProgress } = useMsal();
    const currentAccount = instance.getActiveAccount();
    const role = currentAccount.idTokenClaims['roles'].includes("Global.Admin");
    const getRoles = currentAccount.idTokenClaims['roles'];
    const s_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member");
    const cs_role = currentAccount.idTokenClaims['roles'].includes("CS.Member");
    const csRole_GlobalAdmin = getRoles.some((r) => r === "Global.Admin" || r === "CS.Member");
    const sales_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member" || r === "Trial.Admin");
    const invoice_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member" || r === "Trial.Admin");
    const trial_GlobalAdmin = getRoles.some((r) => r === "Global.Admin" || r === "Trial.Admin");
    const s_cs_t_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member" || r === "CS.Member" || r === "Trial.Admin");
    const [current_package, setCurrent_package] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [allowStakeHolders, setAllowStakeHolders] = useState(null);
    const [allowCallRouting, setAllowCallRouting] = useState(null);
    const [current_owner, setCurrent_owner] = useState('');
    const [current_owner_fullname, setCurrent_owner_fullname] = useState('');
    const [minExpirationDate, setMinExpirationDate] = useState(null);
    const [customerPaymentType, setCustomerPaymentType] = useState('');
    const initialSelectedDate = minExpirationDate ? new Date(minExpirationDate) : new Date();
    initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [actualexpDate, setactualexpDate] = useState(initialSelectedDate);
    const [renewalDate, setRenewalDate] = useState('');
    const modalRef_extension = useRef(null);
    const modalRef_invoice = useRef(null);
    const [showModal_extension, setShowModal_extension] = useState(false);
    const [showModal_invoice, setShowModal_invoice] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalRenew, setShowModalRenew] = useState(false);
    const [actionType, setActionType] = useState('');
    const [showModal_CS, setShowModalForCS] = useState(false);
    const modalRef_addCS = useRef(null);
    const renewModalRef = useRef(null);
    const cancelModalRef = useRef(null);
    const comments_ref = useRef();
    const modalRef_invoiceRenew = useRef(null);
    const modalRef_invoiceCancel = useRef(null);
    const modalRef_AddUsers = useRef(null);
    const [timezoneId, setTimeZoneId] = useState('');
    const [timezoneName, setTimeZoneName] = useState('');
    const [timeZones, setTimezones] = useState([]);
    const modalRef_timezoneChange = useRef(null);
    const [showModal_timezone, setShowModalTimezone] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [csAssigned, setCsAssigned] = useState('');
    const datePickerRef = useRef(null);
    const [userCount_Invoice, setUserCount_Invoice] = useState('');
    const [callRoutingFee, setCallRoutingFee] = useState('');
    const [stakeholderCount_Invoice, setStakeholderCount_Invoice] = useState('');
    const [changeType, setChangeType] = useState("noChange");
    const [showModalAddUsers, setShowModalAddUsers] = useState(false);
    const [additionalUsers, setAdditionalUsers] = useState(0);
    const [additionalStakeholders, setAdditionalStakeholders] = useState(0);
    const [stakeholderAllowed, setStakeHolderAllowed] = useState(false);
    const [callRoutingAllowed, setCallRoutingAllowed] = useState(false);
    const [callRoutingAllowed_trial, setCallRoutingAllowed_trial] = useState(null);

    const [totalAnnualFee, setTotalAnnualFee] = useState(0);
    const [totalAnnualFee_OriginalInvoice, setTotalAnnualFee_OriginalInvoice] = useState(0);
    const [additionalUsersTotalFee, setAdditionalUsersTotalFee] = useState(0);
    // First, add a state for editable call routing fee
    const [editableCallRoutingFee, setEditableCallRoutingFee] = useState('');
    // First, add a state for the invoice total fee
    const [invoiceTotalFee, setInvoiceTotalFee] = useState(0);

    // First, calculate perUserFee once when the modal opens
    const [perUserFee, setPerUserFee] = useState(0);
    // Add this state variable at the top with other state declarations
    const [perStakeholderFee, setPerStakeholderFee] = useState(0);
    // Add this state to store initial values
    const [initialUserCount, setInitialUserCount] = useState(0);
    // Add this state variable at the top
    const [initialStakeholderCount, setInitialStakeholderCount] = useState(0);

    // Update the useEffect for Renew Subscription modal
    useEffect(() => {
        if (showModalRenew) {
            setInitialUserCount(Number(userCount_Invoice));
            setInitialStakeholderCount(Number(stakeholderCount_Invoice));
            const perUserFee = Number(userFee) / Number(userCount_Invoice);
            const perStakeholderFee = Number(stakeholderFee_invoice) / Number(stakeholderCount_Invoice);
            setPerUserFee(perUserFee);
            setPerStakeholderFee(perStakeholderFee);
            setannualFee(userFee);
            // Set initial total annual fee
            setTotalAnnualFee(Number(totalAnnualFee_OriginalInvoice));
        }
    }, [showModalRenew]);

    // Update the useEffect for Add Additional Users modal
    useEffect(() => {
        if (showModalAddUsers) {
            const initialUserFee = Number(annualFee) || Number(userFee) || 0;
            const initialStakeholderFee = stakeholderAllowed ? (Number(stakeholderFee_invoice) || 0) : 0;
            const initialCallRoutingFee = callRoutingAllowed ? (Number(callRoutingFee) || 0) : 0;
            setEditableCallRoutingFee(callRoutingFee);
            setannualFee(userFee); // Set initial user fee
            setAdditionalUsersTotalFee(initialUserFee + initialStakeholderFee + initialCallRoutingFee);
        }
    }, [showModalAddUsers, userFee, stakeholderFee_invoice, callRoutingFee, stakeholderAllowed, callRoutingAllowed]);

    // Update the total fee calculation functions
    const updateTotalFee = (newUserFee, newStakeholderFee, newCallRoutingFee) => {
        const userFeeValue = Number(newUserFee) || 0;
        const stakeholderFeeValue = stakeholderAllowed ? (Number(newStakeholderFee) || 0) : 0;
        const callRoutingFeeValue = callRoutingAllowed ? (Number(newCallRoutingFee) || 0) : 0;
        
        const totalFee = userFeeValue + stakeholderFeeValue + callRoutingFeeValue;
        setTotalAnnualFee(totalFee);
    };

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleShowModal_extension = () => {
        if (modalRef_extension.current) {
            modalRef_extension.current.show = true;
            const initialSelectedDate = new Date(minExpirationDate);
            initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
            const newDate = new Date(expirationDate);
            newDate.setDate(newDate.getDate() + 7);
            setSelectedDate(newDate)
            setactualexpDate(expirationDate)
            setMinExpirationDate(minExpirationDate);
            setShowModal_extension(true);
        }
    };

    const handleShowModal_invoice = () => {
        if (modalRef_invoice.current) {
            modalRef_invoice.current.show = true;
            setShowModal_invoice(true);
        }
    };


    const handleCloseModal_invoice = () => {
        if (modalRef_invoice.current) {
            modalRef_invoice.current.show = false;
            setShowModal_invoice(false);
        }
    };


    const handleShowModal_forCS = () => {
        //setSelectedClientUrl(clientUrl); // Set the selected client URL
        if (modalRef_addCS.current) {
            modalRef_addCS.current.show = true;
            setShowModalForCS(true);
        }
    };

    const handleCloseModal_forCS = () => {
        if (modalRef_addCS.current) {
            modalRef_addCS.current.show = false;
            setShowModalForCS(false);
        }
    };
    const handleShowModal_forTimezone = () => {
        if (modalRef_timezoneChange.current) {
            modalRef_timezoneChange.current.show = true;
            setShowModalTimezone(true);
        }
    };


    const handleCloseModal_forTimeZone = () => {
        if (modalRef_timezoneChange.current) {
            modalRef_timezoneChange.current.show = false;
            setShowModalTimezone(false);
        }
    };



    const handleCloseModal_extension = () => {
        if (modalRef_extension.current) {
            modalRef_extension.current.show = false;
            setShowModal_extension(false);
        }
    };

    const handleSubmit_extendTrial = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            dateId: formd.datepickerId.value

        };

        const accountUrl = todoListData[0].client_url;
        const formattedDate = changeDateFormatAndIncrementDay(formData.dateId);

        extendTrial(accountUrl, formattedDate).then((response) => {

            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {

                toast.success('Trial was Extended. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Trial was not Extended. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_extension();


    };

    const handleSubmit_invoice = (event) => {

        //Get Deal Fields and Information from Pipedrive


        event.preventDefault();

        const formd = event.target;

        const stakeholderCountInput = formd.stakeholderCount;
        const stakeholderFeeInput = formd.stakeholderFee;
        const callRoutingFeeInput = formd.callRoutingFee;

        const formData = {
            subscriptionStartDate: formd.subscriptionStartDate.value,
            userCount: formd.userCount.value,
            stakeholderCount: stakeholderCountInput ? stakeholderCountInput.value || 0 : 0,
            annualFee: formd.annualFee.value,
            stakeholderFee: stakeholderFeeInput ? stakeholderFeeInput.value || 0 : 0,
            callRoutingFee: callRoutingFeeInput ? callRoutingFeeInput.value || 0 : 0,
            customerSuccess: formd.customerSuccess.value,
            serviceProvider: formd.serviceProvider.value,
            invoicePlan: formd.invoicePackage.value,
            salespersonId: formd.matchedEmployeeId.value,
            comments: formd.comments
        };

        const accountUrl = todoListData[0].client_url;


        addInvoice(accountUrl, formData.annualFee, formData.userCount, formData.stakeholderFee, formData.stakeholderCount, formData.callRoutingFee, formatDateSpecific(formData.subscriptionStartDate), formData.invoicePlan, formData.customerSuccess, formData.serviceProvider, formData.salespersonId, formData.comments).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                toast.success('Invoice Added', {
                    onClose: () => {
                        window.location.reload();
                        // history.push('/cspage');
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Invoice addition was unsuccessful. Try again. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });


        handleCloseModal_invoice();
    };


    useEffect(() => {
        setIsLoading(true);
        getSignupDetails(param1, cs_role).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data

                setTodoListData(dataT)
                setCurrent_package(dataT[0].product_package_name)
                setExpirationDate(dataT[0].expiration_date)
                setMinExpirationDate(dataT[0].expiration_date)
                setactualexpDate(dataT[0].expiration_date)
                setAccountType(dataT[0].account_type)
                setuserCount_main(dataT[0].user_count)
                if (cs_role === true) {
                    setCustomerYes(true);
                }
                setCsPerson(<span><strong>CS person:</strong> {dataT[0]?.cs_person_name?.trim() ? dataT[0].cs_person_name : 'Unassigned'}</span>)
                setTimeZoneName(dataT[0].timezone);
                setCsAssigned(dataT[0].cs_person_name)
                setAllowStakeHolders(dataT[0].allow_stakeholders)
                setAllowCallRouting(dataT[0].allow_live_call_routing)
                setStakeHolderCount_main(dataT[0].stakeholder_count)

            }
            else if (response.status == 400) {
                getSignupDetailsForCust(param1).then(response => {
                    if (response.status == 200 || response.status == 201) {
                        dataT = response.data
                        setTodoListData(dataT)
                        setCurrent_package(dataT[0].product_package_name)
                        setExpirationDate(dataT[0].expiration_date)
                        setMinExpirationDate(dataT[0].expiration_date)
                        setactualexpDate(dataT[0].expiration_date)
                        setAccountType(dataT[0].account_type)
                        setuserCount_main(dataT[0].user_count)
                        setCustomerYes(true);
                        setCsPerson(<span><strong>CS person:</strong> {dataT[0]?.cs_person_name?.trim() ? dataT[0].cs_person_name : 'Unassigned'}</span>)
                        setTimeZoneName(dataT[0].timezone);
                        setCsAssigned(dataT[0].cs_person_name)
                        setAllowStakeHolders(dataT[0].allow_stakeholders)
                        setAllowCallRouting(dataT[0].allow_live_call_routing)
                        setStakeHolderCount_main(dataT[0].stakeholder_count)
                    }
                    else {
                        setTodoListData([]);
                        alert("No such account found...")
                        if (cs_role) {
                            document.location.href = "/cspage";
                            setTodoListData("");
                        }
                        else {
                            document.location.href = "/signuplist";
                            setTodoListData("");
                        }
                    }
                });
            }

            else {
                alert("No such account found...")
                if (cs_role) {
                    document.location.href = "/cspage";
                    setTodoListData("");
                }
                else {
                    document.location.href = "/signuplist";
                    setTodoListData("");
                }

            }
        }).finally(() => setIsLoading(false));

    }, []);

    //Get Salesguy
    const getSalesGuy = () => {
        let oId;
        let showSalesPerson;
        let type;
        getDealFromSubdomain(param1).then((response) => {

            if (response.data.data.items.length === 0) {


                setSalesPersonContent(<span><strong>Sales person: </strong> None </span>);
            }
            else {

                oId = response.data.data.items[0].item.owner.id;
                type = "Sales Person";
                getOwner(oId, type);


            }

        });

    };

    const getOwner = (oId, type) => {
        let showSalesPerson;
        getSalesGuyFromOwnerID(oId).then((response) => {
            setSalesPersonEmail(response.data.data.email);
            showSalesPerson = <p>{type}: <strong>{response.data.data.name}</strong></p>

            setSalesPersonContent(<span><strong>Sales person:</strong> {response.data.data.name}</span>);

        });

    }

    const getPipeDealID = () => {
        let dealId;
        getDealFromSubdomain(param1).then((response) => {

            if (response.data.data.items.length === 0) {
                setDealIdContent(<span style={{ display: 'none' }}><strong>(No Deal ID)</strong></span>);
            }
            else {
                dealId = response.data.data.items[0].item.id;
                setDealId(dealId);
                setDealIdContent(<span style={{ display: 'none' }}><strong>{dealId}</strong></span>);
                getAllDealInfoFromPipe(dealId)
            }


        });

    }

    const getAllDealInfoFromPipe = (dealId) => {
        getDealDetailsFromPipeDealID(dealId).then((response) => {
            const transformedDetails = Object.entries(response.data.data).map(([key, value]) => {
                // Use the key name from the window.appConfig if available, otherwise keep the original key
                const keyName = window.appConfig[key] || key;
                return { key: keyName, value: value === null ? 'N/A' : value }; // Create an object for each key-value pair
            });

            setDealDetails(transformedDetails);

        });


    }

    useEffect(() => {
        fetchSalesPeople();
    }, []);

    useEffect(() => {
        getSalesGuy();
    }, []);

    useEffect(() => {
        getPipeDealID();
    }, []);

    useEffect(() => {
        fetchPackages();
    }, []);

    useEffect(() => {
        fetchAdmins();
    }, []);

    useEffect(() => {
        fetchTimeZones();
    }, []);

    useEffect(() => {
        fetchServiceProviders();
    }, []);

    useEffect(() => {
        fetchCSPeople();
    }, []);

    useEffect(() => {
        fetchInvoicePackage();
    }, []);

    useEffect(() => {
        getSubscriptionInfo();
    }, []);

    const getSubscriptionInfo = () => {
        getSubscriptionDetails(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                if (dataT.length !== 0) {
                    setCustomerPaymentType(dataT[0].paymentmethod);
                    setUserCount_Invoice(dataT[0].userlicenses)
                    setStakeholderCount_Invoice(dataT[0].stakeholder_licenses)
                    setStakeholderFee_invoice(dataT[0].stakeholderfee)
                    setUserFee(dataT[0].user_fee)
                    setTotalAnnualFee_OriginalInvoice(dataT[0].annualfee)
                    setSubscriptionPackageId(dataT[0].productpackageid)
                    setSubscriptionPackageName(dataT[0].product_package_name)
                    setRenewalDate(dataT[0].renewaldate)
                    setStakeHolderAllowed(dataT[0].stakeholders);
                    setCallRoutingAllowed(dataT[0].call_routing);
                    setCallRoutingFee(dataT[0].call_routing_fee);
                    setSubscriptionData(dataT);
                }

            }
            else {
                setSubscriptionData([]);
            }
        });

    }


    const fetchServiceProviders = () => {
        getServiceProviders(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableServiceProvider(dataT);

            }
            else {
                setAvailableServiceProvider([]);
            }
        });

    }

    const fetchCSPeople = () => {
        getCSPeople(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableCustomerSuccess(dataT);

            }
            else {
                setAvailableCustomerSuccess([]);
            }
        });

    }

    const fetchSalesPeople = () => {
        getSalesPeople(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableSalesPeople(dataT);

            }
            else {
                setAvailableSalesPeople([]);
            }
        });

    }



    const fetchInvoicePackage = () => {
        getInvoicePackage(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setInvoicePlans(dataT);

            }
            else {
                setInvoicePlans([]);
            }
        });

    }


    //Fetch Packages 
    const fetchPackages = () => {
        getPackages(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailablePackages(dataT);
            }
            else {
                setAvailablePackages([]);
            }
        });
    }

    //Fetch Admins 
    const fetchAdmins = () => {
        getAdmins(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                const ownerUsername = dataT.find(obj => obj.rolename === 'Owner')?.username;
                const ownerFirstName = dataT.find(obj => obj.rolename === 'Owner')?.firstname;
                const ownerLastName = dataT.find(obj => obj.rolename === 'Owner')?.lastname;
                const ownerFullName = ownerFirstName + "," + ownerLastName;
                setCurrent_owner(ownerUsername);
                setCurrent_owner_fullname(ownerFullName);
                setAvailableOwners(dataT);
            }
            else {
                setAvailableOwners([]);
            }
        });
    }
    //Fetch Timezones
    const fetchTimeZones = () => {
        getTimeZones().then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setTimeZoneId(dataT.id);
                setTimezones(dataT);
            }
            else {
                setTimezones([]);
            }
        });
    }

    // Handle selection change
    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
    };
    const handleOwnerChange = (e) => {
        setSelectedOwner(e.target.value);
    };
    const handleServiceProvider = (e) => {
        setSelectedServiceProvider(e.target.value);
    };
    const handleInvoicePlan = (e) => {
        setSelectedInvoicePlan(e.target.value);
    };
    const handleCustomerSuccess = (e) => {
        setSelectedCustomerSuccess(e.target.value);
    };
    const handleTimezoneChange = (e) => {
        setSelectedTimezone(e.target.value);
    };



    // Trigger the modal by setting its show prop to true
    const handleShowModal = () => {
        if (modalRef.current) {
            modalRef.current.show = true;
            setShowModal(true);
        }
    };

    const handleShowModal_forPackage = () => {
        if (modalRef_planChange.current) {
            modalRef_planChange.current.show = true;
            setShowModalPackage(true);
        }
    };

    const handleShowModal_forOwner = () => {
        if (modalRef_ownerChange.current) {
            modalRef_ownerChange.current.show = true;
            setShowModalOwner(true);
        }
    };

    // Hide the modal by setting its show prop to false
    const handleCloseModal = () => {
        if (modalRef.current) {
            modalRef.current.show = false;
            setShowModal(false);
        }
    };

    const handleCloseModal_forPackage = () => {
        if (modalRef_planChange.current) {
            modalRef_planChange.current.show = false;
            setShowModalPackage(false);
        }
    };

    const handleCloseModal_forOwner = () => {
        if (modalRef_ownerChange.current) {
            modalRef_ownerChange.current.show = false;
            setShowModalOwner(false);
        }
    };

    //const handleShowModal = () => setShowModal(true);
    //const handleCloseModal = () => setShowModal(false);
    const handleSubmit = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            twilioId: formd.text1.value,
            state: formd.text2.value,
            country: formd.text3.value,
            areacode: formd.text4.value
        };

        const accountUrl = todoListData[0].client_url;

        enableLCR(accountUrl, formData.twilioId, formData.country, formData.state, formData.areacode).then((response) => {
            if ((response === 200 || response === 201 || response === 204)) {


                toast.success('Live Call Routing was enabled. Page will Reload now', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Live Call Routing was not enabled. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal();

    };

    const handleSubmit_changePlan = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            selected_package: formd.text2.value,
            package_id: formd.text2.key

        };

        const accountUrl = todoListData[0].client_url;
        const selectedPackage_Id = availablePackages.find((pack) => pack.name === formData.selected_package)?.id;



        changePackage(accountUrl, formData.selected_package, selectedPackage_Id).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {


                toast.success('Package Changed. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Package change was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forPackage();



    };

    const handleSubmit_changeOwner = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            ownerChangeUsername: formd.text3.value

        };

        const accountUrl = todoListData[0].client_url;



        changeOwner(accountUrl, formData.ownerChangeUsername).then((response) => {
            if ((response === 200 || response === 201 || response === 204)) {

                toast.success('Owner Updated. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Owner Update was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forOwner();



    };

    const handleSubmit_assignCs = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            selected_cs: formd.customerSuccess.value

        };

        const accountUrl = todoListData[0].client_url;

        assignCs(accountUrl, formData.selected_cs).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                toast.success('Customer Success Person Re-assigned. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('CS Re-assignment was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forCS();
    };

    const handleSubmit_changeTimezone = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            timezoneChangeId: formd.text3.value
        };

        const accountUrl = todoListData[0].client_url;

        changeTimeZone(accountUrl, formData.timezoneChangeId).then((response) => {
            if ((response.status === 200 || response.status === 201 || response.status === 204) && (response.data[0].product_package_id !== 0)) {
                toast.success('Time zone Updated. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {
                toast.error('Time zone Update was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forTimeZone();
    };




    // Use useEffect to handle side effects from changes to salesPersonEmail or availableSalesPeople
    useEffect(() => {
        const employeeEmailPrefix = salesPersonEmail.split('@')[0].toLowerCase();
        const matchedEmployee = availableSalesPeople.find(person => person.employee.toLowerCase() === employeeEmailPrefix);

        if (matchedEmployee) {
            setMatchedEmployeeId(matchedEmployee.employee_id);
        } else {
            setMatchedEmployeeId('0'); // Use '0' or any other placeholder that indicates 'no match found'
        }
    }, [salesPersonEmail, availableSalesPeople]); // Depend on salesPersonEmail and availableSalesPeople to re-run this effect

    const handleUserCountChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        // Check if the parsed number is a valid number
        if (!isNaN(newCount)) {
            setuserCount_main(newCount);
        }
    };

    const handleUserCount_InvoiceChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        if (!isNaN(newCount)) {
            setUserCount_Invoice(newCount);
        }
    };

    const handleUserCountBlur = () => {
        // Ensure the value doesn't go below the initial user count
        if (userCount < userCount_main) {
            setuserCount_main(userCount_main);
        }
    }

    const handleUserCount_InvoiceBlur = () => {
        if (userCount < userCount_Invoice) {
            setUserCount_Invoice(userCount_Invoice);
        }
    }

    const handleStakeholderCountChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        // Check if the parsed number is a valid number
        if (!isNaN(newCount)) {
            setStakeHolderCount_main(newCount);
        }
    };

    const handleStakeholderCount_InvoiceChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        if (!isNaN(newCount)) {
            setStakeholderCount_Invoice(newCount);
        }
    };

    const handleStakeholderCountBlur = () => {
        // Ensure the value doesn't go below the initial user count
        if (stakeholderCount < stakeholderCount_main) {
            setStakeHolderCount_main(stakeholderCount_main);
        }
    }

    const handleStakeholderCount_InvoiceBlur = () => {
        if (stakeholderCount < stakeholderCount_Invoice) {
            setStakeholderCount_Invoice(stakeholderCount_Invoice);
        }
    }


    const handleSubscriptionAction = (comments, action, values) => {
        subscriptionData.forEach(subscription => {
            console.log(subscription);
        });
        // Perform the required action (renew or cancel) with the subscription information
        const accountUrl = todoListData[0].client_url;
        if (action === 'renew') {
            renewInvoice(accountUrl, values.userCount, values.annualFee, values.stakeholderCount, values.stakeholderFee, values.callRoutingFee, subscriptionPackageId, formatDateSpecific(values.effectivedate), values.reason).then((response) => {
                if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                    toast.success('Invoice Renewed. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
                else {

                    toast.error('Invoice Renew Failed. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
            });
        }

        else if (action === 'addUsers') {
            addUsers(accountUrl, values.userCount, values.annualFee, values.stakeholderCount, values.stakeholderFee, values.callRoutingFee, subscriptionPackageId, formatDateSpecific(values.effectivedate), values.reason).then((response) => {
                if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                    toast.success('Added Additional Users. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
                else {

                    toast.error('User Addition Failed. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
            });
        }

        else {
            cancelInvoice(accountUrl, formatDateSpecific(values.effectivedate), comments).then((response) => {
                if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                    toast.success('Invoice Cancelled. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
                else {

                    toast.error('Invoice Cancel Failed. Page will Reload', {
                        onClose: () => {
                            window.location.reload();
                        },
                        autoClose: 3000
                    });
                }
            });

        }
    };

    const handleShowModal_forRenewInvoice = () => {
        if (modalRef_invoiceRenew.current) {
            modalRef_invoiceRenew.current.show = true;
            setActionType('renew');
            setShowModalRenew(true);
        }
    };

    // Hide the modal by setting its show prop to false
    const handleCloseModal_forRenewInvoice = () => {
        if (modalRef_invoiceRenew.current) {
            modalRef_invoiceRenew.current.show = false;
            setShowModalRenew(false);
        }
    };

    const handleShowModal_forCancelInvoice = () => {
        if (modalRef_invoiceCancel.current) {
            modalRef_invoiceCancel.current.show = true;
            setActionType('cancel');
            setShowModalConfirm(true);
        }
    };

    // Hide the modal by setting its show prop to false
    const handleCloseModal_forCancelInvoice = () => {
        if (modalRef_invoiceCancel.current) {
            modalRef_invoiceCancel.current.show = false;
            setShowModalConfirm(false);
        }
    };


    const handleConfirmAction = (confirmed) => {

        if (confirmed) {
            const datePickerValue = datePickerRef.current.input.value; // Get the value directly
            const cancelReason = comments
            handleSubscriptionAction(cancelReason, actionType, { effectivedate: datePickerValue }); // Pass the values as needed
        }
        setShowModalConfirm(false);
    };

    const handleRenewSubmit = (event) => {
        event.preventDefault();
        const formd = event.target;

        const values = {
            userCount: formd.userCount.value,
            annualFee: formd.annualFee.value,
            callRoutingFee: allowCallRouting === 1 ? formd.callRoutingFee.value : 0,
            stakeholderCount: allowStakeHolders === 1 ? formd.stakeholderCount.value : 0,
            stakeholderFee: allowStakeHolders === 1 ? formd.stakeholderFee.value : 0,
            effectivedate: formd.subscriptionStartDate.value,
            reason: formd.comments.value
        };

        handleSubscriptionAction("", 'renew', values);
        handleCloseModal_forRenewInvoice();
    };

    const handleAddUsers = (event) => {
        event.preventDefault();
        const formd = event.target;

        // Add validation for effective date
        if (!selectedDate_I) {
            toast.error('Please select an Effective Date');
            return;
        }

        const values = {
            userCount: formd.totalUsers.value,
            annualFee: formd.annualFee.value,
            stakeholderCount: allowStakeHolders === 1 ? formd.totalStakeholders.value : 0,
            stakeholderFee: allowStakeHolders === 1 ? formd.stakeholderFee.value : 0,
            callRoutingFee: allowCallRouting === 1 ? formd.callRoutingFee.value : 0,
            effectivedate: formd.subscriptionStartDate.value,
            reason: formd.comments.value
        };

        handleSubscriptionAction("", 'addUsers', values);
        handleCloseModal_forAddUsers();
    };


    const handleShowModal_forAddUsers = () => {
        if (modalRef_AddUsers.current) {
            modalRef_AddUsers.current.show = true;
            setActionType('AddUsers');
            setShowModalAddUsers(true);
        }
    };

    // Hide the modal by setting its show prop to false
    const handleCloseModal_forAddUsers = () => {
        if (modalRef_AddUsers.current) {
            modalRef_AddUsers.current.show = false;
            setShowModalAddUsers(false);
        }
    };

    // Add useEffect to set initial total fee when the Add Additional Users modal opens
    useEffect(() => {
        if (showModalAddUsers) {
            const initialUserFee = Number(userFee) || 0;
            const initialStakeholderFee = stakeholderAllowed ? (Number(stakeholderFee_invoice) || 0) : 0;
            const initialCallRoutingFee = callRoutingAllowed ? (Number(callRoutingFee) || 0) : 0;
            setEditableCallRoutingFee(callRoutingFee); // Set initial value from callRoutingFee
            setAdditionalUsersTotalFee(initialUserFee + initialStakeholderFee + initialCallRoutingFee);
        }
    }, [showModalAddUsers, userFee, stakeholderFee_invoice, callRoutingFee, stakeholderAllowed, callRoutingAllowed]);

    // Add function to update total fee for additional users modal
    const updateAdditionalUsersTotalFee = (newUserFee, newStakeholderFee, newCallRoutingFee) => {
        const userFeeValue = Number(newUserFee) || 0;
        const stakeholderFeeValue = stakeholderAllowed ? (Number(newStakeholderFee) || 0) : 0;
        const callRoutingFeeValue = callRoutingAllowed ? (Number(newCallRoutingFee) || 0) : 0;
        const totalFee = userFeeValue + stakeholderFeeValue + callRoutingFeeValue;
        setAdditionalUsersTotalFee(totalFee);
    };

    // Add a function to calculate invoice total fee
    const updateInvoiceTotalFee = (userFee, stakeholderFee, callRoutingFee) => {
        const userFeeValue = Number(userFee) || 0;
        const stakeholderFeeValue = Number(stakeholderFee) || 0;
        const callRoutingFeeValue = Number(callRoutingFee) || 0;
        setInvoiceTotalFee(userFeeValue + stakeholderFeeValue + callRoutingFeeValue);
    };

    // Add these handlers to update total fee when inputs change
    const handleUserFeeChange = (e) => {
        const newUserFee = e.target.value;
        const stakeholderFeeValue = stakeholderFee_ref.current?.value || 0;
        const callRoutingFeeValue = document.getElementById('callRoutingFee')?.value || 0;
        updateInvoiceTotalFee(newUserFee, stakeholderFeeValue, callRoutingFeeValue);
    };

    const handleStakeholderFeeChange = (e) => {
        const newStakeholderFee = e.target.value;
        const userFeeValue = annualFee_ref.current?.value || 0;
        const callRoutingFeeValue = document.getElementById('callRoutingFee')?.value || 0;
        updateInvoiceTotalFee(userFeeValue, newStakeholderFee, callRoutingFeeValue);
    };

    const handleCallRoutingFeeChange = (e) => {
        const newCallRoutingFee = e.target.value;
        const userFeeValue = annualFee_ref.current?.value || 0;
        const stakeholderFeeValue = stakeholderFee_ref.current?.value || 0;
        updateInvoiceTotalFee(userFeeValue, stakeholderFeeValue, newCallRoutingFee);
    };

        // Add this function to calculate the user fee
    const calculateUserFee = (additionalUserCount) => {
        const existingUserCount = Number(userCount_Invoice) || 0;
        const existingUserFee = Number(userFee) || 0;
        
        if (existingUserCount === 0) return existingUserFee;
        
        const feePerUser = existingUserFee / existingUserCount;
        const additionalFee = additionalUserCount * feePerUser;
        const totalUserFee = existingUserFee + additionalFee;
        
        return Math.round(totalUserFee); // Round to nearest integer
    };

    // Add this function to calculate the stakeholder fee
    const calculateStakeholderFee = (additionalStakeholderCount) => {
        const existingStakeholderCount = Number(stakeholderCount_Invoice) || 0;
        const existingStakeholderFee = Number(stakeholderFee_invoice) || 0;
        
        if (existingStakeholderCount === 0) return existingStakeholderFee;
        
        const feePerStakeholder = existingStakeholderFee / existingStakeholderCount;
        const additionalFee = additionalStakeholderCount * feePerStakeholder;
        const totalStakeholderFee = existingStakeholderFee + additionalFee;
        
        return Math.round(totalStakeholderFee); // Round to nearest integer
    };

    // Update the useEffect to calculate both per-user and per-stakeholder fees when modal opens
    useEffect(() => {
        if (showModalRenew) {
            const perUserFee = Number(userFee) / Number(userCount_Invoice);
            setPerUserFee(perUserFee);

            // Calculate per stakeholder fee
            const perStakeholderFee = Number(stakeholderFee_invoice) / Number(stakeholderCount_Invoice);
            setPerStakeholderFee(perStakeholderFee);
        }
    }, [showModalRenew]);




    function formatDate(selDate) {
        const exp_createDate_Obj = new Date(selDate);
        let dateFormatted;
        const weekday = new Array("Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat");
        let day = weekday[exp_createDate_Obj.getDay()];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let month = months[exp_createDate_Obj.getMonth()];
        let hours = exp_createDate_Obj.getHours();
        const ampmSwitch = (hours > 12) ? "PM" : "AM";
        if (hours > 12) {
            hours -= 12;

        }
        else if (hours === 0) {
            hours = 12;
        }

        dateFormatted = month + " " + exp_createDate_Obj.getDate() + "," + exp_createDate_Obj.getFullYear() + " at " + exp_createDate_Obj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return dateFormatted;
    }


    const formatNames = (names) => {
        if (names === null) {
            return "None";
        }
        //return names.replace(/\\n/g, <br />);
        const namesArray = names.split("\\n");
        return namesArray.map((name, index) => (
            <React.Fragment key={index}>
                {name}
                <br /><br />
            </React.Fragment>
        ));

    };

    function formatDateSpecific(dateString) {
        // Split the string into day, month, and year parts
        const [month, day, year] = dateString.split('/');

        // Return the date in the desired format
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }


    function changeDateFormatAndIncrementDay(dateVal) {
        // Split the date string into parts: month, day, and year
        const parts = dateVal.split('/');

        // Create a Date object with the original date
        const originalDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);

        // Increment the day by 1
        originalDate.setDate(originalDate.getDate() + 1);

        // Get the year, month, and day components of the updated date
        const year = originalDate.getFullYear();
        const month = originalDate.getMonth() + 1; // Months are 0-based
        const day = originalDate.getDate() + 1;

        // Ensure two-digit formatting for month and day
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Create the final date string in 'yyyy-mm-dd' format
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        return formattedDate;
    }



    return (
        <div>
            {isLoading ? (
                <center><div className="loader"></div></center>
            ) : (
                <>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
                    <strong>&nbsp;&nbsp; Account:</strong>
                    <span>{param1}</span>
                    <span>|</span>
                    <span>{cs_role === true || accountType === 'Customer' ? csPerson : salesPersonContent}</span>
                    {dealIdContent && <span>{dealIdContent}</span>}
                    {trial_GlobalAdmin === true && <EnableLCRButton onClick={handleShowModal} />}
                    {sales_role === true && <EnablePlanChangeButton onClick={handleShowModal_forPackage} />}
                    {csRole_GlobalAdmin === true && <EnableOwnerChangeButton onClick={handleShowModal_forOwner} />}
                    {sales_role === true && accountType !== 'Customer' && (
                        <EnableExtendTrialButton onClick={handleShowModal_extension} />
                    )}
                    {csRole_GlobalAdmin === true && accountType === 'Customer' === true && csAssigned != null && (
                        <EnableCSChangeButton onClick={handleShowModal_forCS} />
                    )}
                    {s_cs_t_role === true && <EnableChangeTimeZoneButton onClick={handleShowModal_forTimezone} />}
                    </div>

                    <br />
                    <div>
                        <strong>&nbsp;&nbsp; Account Type:</strong> <span>{accountType}  &nbsp;&nbsp; {accountType === "Trial" ? <EnableInvoiceButton onClick={handleShowModal_invoice} /> : null} </span>
                    </div>
                    <br />
                    <div className="main-container">
                    <div className="info-container">
                                {todoListData.map((acc) => (
                                    <div key={acc.client_url} className="info-table">
                                    <div className="info-row">
                                        <div className="info-cell key">Client URL:</div>
                                        <div className="info-cell value">{acc.client_url}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">
                                            {accountType === 'Customer' ? 'Subscription Date | Expiration Date:' : 'Created Date | Expiration Date:'}
                                        </div>
                                        <div className="info-cell value">
                                            {accountType === 'Customer' 
                                                ? `${formatDate(subscriptionData[0]?.subscriptionstartdate)} | ${formatDate(acc.expiration_date)}`
                                                : `${formatDate(acc.created_date)} | ${formatDate(acc.expiration_date)}`}
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Package:</div>
                                        <div className="info-cell value">{acc.product_package_name}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Timezone:</div>
                                        <div className="info-cell value">{acc.timezone}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Last Login Date:</div>
                                        <div className="info-cell value">{formatDate(acc.last_login_date_cst)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Account Owner:</div>
                                        <div className="info-cell value">{current_owner_fullname}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Account Owner Email | Phone:</div>
                                        <div className="info-cell value">{acc.contact_email} | {acc.contact_phone}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">User Count:</div>
                                        <div className="info-cell value">{acc.user_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Stakeholder Count:</div>
                                        <div className="info-cell value">{acc.stakeholder_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Group Count:</div>
                                        <div className="info-cell value">{acc.groups_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Schedule Count:</div>
                                        <div className="info-cell value">{acc.schedulecount}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Escalation Policy Count:</div>
                                        <div className="info-cell value">{acc.escalation_policy_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Workflow Count:</div>
                                        <div className="info-cell value">{acc.workflow_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Call Routing Count:</div>
                                        <div className="info-cell value">{acc.call_routing_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Alerts Count:</div>
                                        <div className="info-cell value">{acc.alerts_count}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Last Alert Created Date:</div>
                                        <div className="info-cell value">{formatDate(acc.last_alert_created_date)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Inbound Integrations:</div>
                                        <div className="info-cell value">{formatNames(acc.inbound_integration_names)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Outbound Integrations:</div>
                                        <div className="info-cell value">{formatNames(acc.outbound_integration_names)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Call Routing Instances:</div>
                                        <div className="info-cell value">{formatNames(acc.call_routingnames)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Escalation Policies in Integrations:</div>
                                        <div className="info-cell value">{formatNames(acc.escalation_policies_in_integrations)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Escalation Policies in Workflows:</div>
                                        <div className="info-cell value">{formatNames(acc.escalation_policies_in_workflows)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Workflows in Escalation Policies:</div>
                                        <div className="info-cell value">{formatNames(acc.workflowsin_escalation_policies)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Outbound Integrations in Workflows:</div>
                                        <div className="info-cell value">{formatNames(acc.outbound_in_workflows)}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="info-cell key">Outbound Integrations in Escalation Policies:</div>
                                        <div className="info-cell value">{formatNames(acc.outbound_in_escalation_policies)}</div>
                                    </div>
                                    </div>
                                ))}
                            </div>
                            {accountType === 'Customer' && (
                            <div className="customer-info">
                                <h3>Subscription Information</h3>
                                {subscriptionData.map((subscription, index) => (
                                    <div key={index} className="subscription-details">
                                        <div className="cust pair">
                                            <span className="key"><strong>Payment Method: </strong></span>
                                            <span className="value">{subscription.paymentmethod}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Annual Fee: </strong></span>
                                            <span className="value">{subscription.annualfee}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>User Count: </strong></span>
                                            <span className="value">{subscription.userlicenses}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>User Fee: </strong></span>
                                            <span className="value">{subscription.user_fee}</span>
                                        </div>
                                        {stakeholderAllowed && (
                                            <>
                                        <div className="cust pair">
                                            <span className="key"><strong>Stakeholder Count: </strong></span>
                                            <span className="value">{subscription.stakeholder_licenses || 0}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Stakeholder Fee: </strong></span>
                                            <span className="value">{subscription.stakeholderfee || 0}</span>
                                        </div>
                                            </>
                                        )}
                                        {callRoutingAllowed && (
                                        <div className="cust pair">
                                            <span className="key"><strong>Call Routing Fee: </strong></span>
                                            <span className="value">{subscription.call_routing_fee ? subscription.call_routing_fee : 'N/A'}</span>
                                        </div>
                                        )}
                                        <div className="cust pair">
                                            <span className="key"><strong>Last Payment Date: </strong></span>
                                            <span className="value">{subscription.last_payment_date ? formatDate(subscription.last_payment_date) : 'N/A'}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Product Package Name: </strong></span>
                                            <span className="value">{subscription.product_package_name}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>License Term Type: </strong></span>
                                            <span className="value">{subscription.license_term_type}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Effective Date: </strong></span>
                                            <span className="value">{formatDate(subscription.effectivedate)}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Subscription Start Date: </strong></span>
                                            <span className="value">{formatDate(subscription.subscriptionstartdate)}</span>
                                        </div>
                                        <div className="cust pair">
                                            <span className="key"><strong>Renewal Date: </strong></span>
                                            <span className="value">{formatDate(subscription.renewaldate)}</span>
                                        </div>
                                    </div>
                                ))}
                                <br />
                                {customerPaymentType === 'Invoice' && role === true && (
                                    <div className="button-container">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleShowModal_forRenewInvoice}
                                        >
                                            Renew Subscription
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleShowModal_forAddUsers}
                                        >
                                            Add Additional Users
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={handleShowModal_forCancelInvoice}
                                        >
                                            Cancel Invoice
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        <br />
                    </div>
                    <center></center>

                    <Modal ref={modalRef} show={showModal} onHide={handleCloseModal} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enable Live Call Routing</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="text1" className="form-label"><strong>Twilio Account ID:</strong></label>
                                    <input type="text" className="form-control" id="text1" ref={text1Ref} value={text1} onChange={(e) => setText1(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>State:</strong></label>
                                    <input type="text" className="form-control" id="text2" ref={text2Ref} value={text2} onChange={(e) => setText2(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text3" className="form-label"><strong>Country:</strong></label>
                                    <input type="text" className="form-control" id="text3" value={text3} ref={text3Ref} onChange={(e) => setText3(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text4" className="form-label"><strong>Area Code:</strong></label>
                                    <input type="text" className="form-control" id="text4" value={text4} ref={text4Ref} onChange={(e) => setText4(e.target.value)} required />
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </Modal.Body>
                    </Modal>

                    <Modal ref={modalRef_planChange} show={showModal_package} onHide={handleCloseModal_forPackage} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_changePlan}>
                                <div className="mb-3">
                                    <label htmlFor="text1" className="form-label"><strong>Current Package:</strong> {current_package}</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Available Packages: </strong>&nbsp;</label>
                                    <select
                                        id="text2"
                                        className="form-select"
                                        value={selectedPackage}
                                        onChange={handlePackageChange}
                                        required
                                    >
                                        <option value="">Select a package</option>
                                        {availablePackages.map((pack) => (
                                            <option key={pack.id} value={pack.name}>
                                                {pack.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal ref={modalRef_timezoneChange} show={showModal_timezone} onHide={handleCloseModal_forTimeZone} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Time Zone</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_changeTimezone}>
                                <div className="mb-3">
                                    <label htmlFor="text1" className="form-label"><strong>Current Timezone:</strong> {timezoneName}</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Time Zones: </strong>&nbsp;</label>
                                    <select
                                        id="text3"
                                        className="form-select"
                                        value={selectedTimezone}
                                        onChange={handleTimezoneChange}
                                        required
                                    >
                                        <option value="">Select a time zone</option>
                                        {timeZones.map((timezone) => (
                                            <option key={timezone.id} value={timezone.id}>
                                                {timezone.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal ref={modalRef_ownerChange} show={showModal_owner} onHide={handleCloseModal_forOwner} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Owner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_changeOwner}>
                                <div className="mb-3">
                                    <label htmlFor="text1" className="form-label"><strong>Current Owner:</strong> {current_owner_fullname}</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Available Admins: </strong>&nbsp;</label>
                                    <select
                                        id="text3"
                                        className="form-select"
                                        value={selectedOwner}
                                        onChange={handleOwnerChange}
                                        required
                                    >
                                        <option value="">Select an Admin</option>
                                        {availableOwners.map((admin) => (
                                            <option key={admin.username} value={admin.username}>
                                                {admin.firstname} {admin.lastname}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </Modal.Body>
                    </Modal>

                    <Modal ref={modalRef_extension} show={showModal_extension} onHide={handleCloseModal_extension} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Extend Trial</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_extendTrial}>
                                <p>Default date selected is 7 days from expiration date (Expires {formatDate(minExpirationDate)})</p>
                                <div className="ed" id="eDate">
                                    &#128197;<DatePicker id="datepickerId"
                                        value={new Date(new Date(minExpirationDate).getTime() + 7 * 24 * 60 * 60 * 1000)}
                                        selected={selectedDate}
                                        onSelect={(date) => setSelectedDate(date)}
                                        minDate={new Date(actualexpDate)}
                                        maxDate={new Date(new Date(actualexpDate).getTime() + 7 * 24 * 60 * 60 * 1000)}
                                        placeholderText="Select Date(7 days max)"
                                    />
                                </div>
                                <br />
                                <button type="submit" className="btn btn-primary">Extend</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal ref={modalRef_invoice} show={showModal_invoice} onHide={handleCloseModal_invoice} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Annual Invoice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_invoice}>
                                
                                <div className="mb-3">
                                    <label htmlFor="text1" className="form-label"><strong>Current Package:</strong> {current_package}</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Select Package: </strong>&nbsp;</label>
                                    <select
                                        id="invoicePackage"
                                        className="form-select"
                                        value={selectedInvoicePlan}
                                        onChange={handleInvoicePlan}
                                        required
                                    >
                                        <option value="">Select a package</option>
                                        {invoicePlans.map((pack) => (
                                            <option key={pack.id} value={pack.id}>
                                                {pack.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Select Customer Success Person: </strong>&nbsp;</label>
                                    <select
                                        id="customerSuccess"
                                        className="form-select"
                                        value={selectedCustomerSuccess}
                                        onChange={handleCustomerSuccess}
                                        required
                                    >
                                        <option value="">Select Customer Success</option>
                                        {availableCustomerSuccess.map((pack) => (
                                            <option key={pack.employee_id} value={pack.employee_id}>
                                                {pack.employeename}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text2" className="form-label"><strong>Select Service Provider Type: </strong>&nbsp;</label>
                                    <select
                                        id="serviceProvider"
                                        className="form-select"
                                        value={selectedServiceProvider}
                                        onChange={handleServiceProvider}
                                        required
                                    >
                                        <option value="">Select Service Provider Type</option>
                                        {availableServiceProvider.map((pack) => (
                                            <option key={pack.service_provider_type} value={pack.service_provider_type}>
                                                {pack.service_provider_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="ed" id="eDate">
                                    &#128197;<DatePicker id="subscriptionStartDate"
                                        selected={selectedDate_I}
                                        onChange={(date) => setSelectedDate_I(date)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Subscription Date"
                                    />
                                </div>
                                <br />
                                <div className="mb-3">
                                    <label htmlFor="comments" className="form-label"><strong>Comments:</strong></label>
                                    <input type="text" className="form-control" id="comments" value={comments} ref={comments_ref} onChange={(e) => setComments(e.target.value)} />
                                </div>
                                <div style={{ display: 'none' }}>
                                    <input type="hidden" id="matchedEmployeeId" value={matchedEmployeeId} />
                                </div>
                                
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label htmlFor="userCount" className="form-label"><strong>User Count:</strong></label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="userCount"
                                            value={userCount_main}
                                            min={userCount_main}
                                            onChange={handleUserCountChange}
                                            onBlur={handleUserCountBlur}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="annualFee" className="form-label"><strong>User Fee:</strong></label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="annualFee" 
                                            ref={annualFee_ref} 
                                            onChange={handleUserFeeChange}
                                            required 
                                        />
                                    </div>
                                </div>
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {allowStakeHolders === 1 && (
                                        <div className="mb-3" style={{ flex: 1 }}>
                                            <label htmlFor="stakeholderCount" className="form-label"><strong>Stake Holder Count:</strong></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="stakeholderCount"
                                                value={stakeholderCount_main}
                                                min={stakeholderCount_main}
                                                onChange={handleStakeholderCountChange}
                                                onBlur={handleStakeholderCountBlur}
                                                required
                                            />
                                        </div>
                                    )}
                                    {allowStakeHolders === 1 && (
                                        <div className="mb-3">
                                            <label htmlFor="stakeholderFee" className="form-label"><strong>Stakeholder Fee:</strong></label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="stakeholderFee" 
                                                ref={stakeholderFee_ref}
                                                onChange={handleStakeholderFeeChange}
                                                required 
                                            />
                                        </div>
                                    )}
                                </div>
                                {allowCallRouting === 1 && (
                                    <div className="mb-3">
                                        <label htmlFor="callRoutingFee" className="form-label">
                                            <strong>Call Routing Fee:</strong>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="callRoutingFee"
                                            onChange={handleCallRoutingFeeChange}
                                            required={allowCallRouting === 1}
                                        />
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label className="form-label">
                                        <strong>Total Annual Fee: </strong>${invoiceTotalFee}
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary">Add Invoice</button>
                            </form>
                        </Modal.Body>
                    </Modal>

                    <Modal ref={modalRef_invoiceCancel} show={showModalConfirm} onHide={handleCloseModal_forCancelInvoice} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel Invoice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="ed" id="eDate">
                                &#128197;<DatePicker ref={datePickerRef} id="subscriptionStartDate"
                                    selected={selectedDate_I}
                                    onChange={(date) => setSelectedDate_I(date)}
                                    minDate={new Date()}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Effective Date"
                                />
                            </div><br />
                            <div className="mb-3">
                                <label htmlFor="comments" className="form-label"><strong>Comments/Reason:</strong></label>
                                <input type="text" className="form-control" id="comments" value={comments} ref={comments_ref} onChange={(e) => setComments(e.target.value)} />
                            </div>
                            <br />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleConfirmAction(false)}>
                                No
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    if (window.confirm('Are you sure? This will completely remove the account.')) {
                                        handleConfirmAction(true);
                                    }
                                }}
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal ref={modalRef_addCS} show={showModal_CS} onHide={handleCloseModal_forCS} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Customer Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleSubmit_assignCs}>
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="text1" className="form-label"><strong>Current CS Person:</strong> {csAssigned}</label>
                                    </div>
                                    <label htmlFor="text2" className="form-label"><strong>Select Customer Success Person: </strong>&nbsp;</label>
                                    <select
                                        id="customerSuccess"
                                        className="form-select"
                                        value={selectedCustomerSuccess}
                                        onChange={handleCustomerSuccess}
                                        required
                                    >
                                        <option value="">Select Customer Success</option>
                                        {availableCustomerSuccess.map((pack) => (
                                            <option key={pack.employee_id} value={pack.employee_id}>
                                                {pack.employeename}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <p><strong>Account:</strong> {param1}</p> {/* Display the selected client URL */}
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal ref={modalRef_invoiceRenew} show={showModalRenew} onHide={handleCloseModal_forRenewInvoice} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Renew Subscription</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleRenewSubmit}>
                                {/* Radio Buttons for Change Options */}
                                <div className="form-row mb-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="userOptions"
                                            id="noChange"
                                            value="noChange"
                                            checked={changeType === "noChange"}
                                            onChange={() => {
                                                setChangeType("noChange");
                                                // Reset to original values
                                                setUserCount_Invoice(initialUserCount);
                                                setannualFee(userFee);
                                                setTotalAnnualFee(Number(totalAnnualFee_OriginalInvoice));  // Use original total annual fee
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="noChange">
                                            No Change
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="userOptions"
                                            id="downgradeUsers"
                                            value="downgradeUsers"
                                            checked={changeType === "downgradeUsers"}
                                            onChange={() => {
                                                setChangeType("downgradeUsers");
                                                // Reset to initial values
                                                setUserCount_Invoice(initialUserCount);
                                                setannualFee(userFee);
                                                // Calculate total fee including call routing fee
                                                setTotalAnnualFee(Number(userFee) + 
                                                    (stakeholderAllowed ? Number(stakeholderFee_invoice) : 0) + 
                                                    (callRoutingAllowed ? Number(callRoutingFee) : 0)  // Add call routing fee
                                                );
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="downgradeUsers">
                                            Downgrade Users
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="userOptions"
                                            id="upgradeUsers"
                                            value="upgradeUsers"
                                            checked={changeType === "upgradeUsers"}
                                            onChange={() => {
                                                setChangeType("upgradeUsers");
                                                // Reset to initial values
                                                setUserCount_Invoice(initialUserCount);
                                                setannualFee(userFee);
                                                // Calculate total fee including call routing fee
                                                setTotalAnnualFee(Number(userFee) + 
                                                    (stakeholderAllowed ? Number(stakeholderFee_invoice) : 0) + 
                                                    (callRoutingAllowed ? Number(callRoutingFee) : 0)  // Add call routing fee
                                                );
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="upgradeUsers">
                                            Upgrade Users
                                        </label>
                                    </div>
                                </div>

                                {/* Package and Renewal Date */}
                                <div className="form-row mb-3">
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label className="form-label">
                                            <strong>Package: </strong>{subscriptionPackageName}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label className="form-label">
                                            <strong>Renewal Date: </strong>{formatDate(renewalDate)}
                                        </label>
                                        <input 
                                            type="hidden" 
                                            id="subscriptionStartDate" 
                                            value={new Date(renewalDate).toLocaleDateString('en-US')} 
                                        />
                                    </div>
                                </div>

                                {/* Static Fee Labels */}
                                {(changeType === "downgradeUsers" || changeType === "upgradeUsers") && (
                                    <div className="form-row mb-3">
                                        <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                            <label className="form-label">
                                                <strong>Existing User Fee: </strong>${userFee}
                                            </label>
                                        </div>
                                        {stakeholderAllowed && (
                                            <div className="mb-3" style={{ flex: 1 }}>
                                                <label className="form-label">
                                                    <strong>Existing Stakeholder Fee: </strong>${stakeholderFee_invoice || 0}
                                                </label>
                                            </div>
                                        )}
                                        {/* {callRoutingAllowed && (
                                            <div className="mb-3" style={{ flex: 1 }}>
                                                <label className="form-label">
                                                    <strong>Call Routing Fee: </strong>${callRoutingFee || 0}
                                                </label>
                                            </div>
                                        )} */}
                                    </div>
                                )}

                                {/* User Count Section */}
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label htmlFor="userCount" className="form-label"><strong>User Count:</strong></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="userCount"
                                            value={userCount_Invoice}
                                            disabled={changeType === "noChange"}
                                            onChange={(e) => {
                                                // Only allow numbers
                                                const value = e.target.value.replace(/\D/g, '');
                                                const newUserCount = Number(value);
                                                
                                                // Calculate new user fee using the constant perUserFee
                                                const newUserFee = perUserFee * newUserCount;
                                                
                                                // Update states
                                                setUserCount_Invoice(newUserCount);
                                                setannualFee(Math.round(newUserFee));
                                                
                                                // Calculate total fee
                                                const totalFee = Math.round(newUserFee) + 
                                                    (stakeholderAllowed ? Number(stakeholderFee_invoice) : 0) + 
                                                    (callRoutingAllowed ? Number(editableCallRoutingFee) : 0);
                                                
                                                setTotalAnnualFee(totalFee);
                                            }}
                                            onBlur={(e) => {
                                                const currentValue = Number(e.target.value);
                                                
                                                if (changeType === "upgradeUsers" && currentValue < initialUserCount) {
                                                    e.target.setCustomValidity("For Upgrade Users, count cannot be less than the original count");
                                                    e.target.reportValidity();
                                                } else if (changeType === "downgradeUsers" && currentValue > initialUserCount) {
                                                    e.target.setCustomValidity("For Downgrade Users, count cannot be more than the original count");
                                                    e.target.reportValidity();
                                                } else {
                                                    e.target.setCustomValidity("");
                                                }
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="annualFee" className="form-label"><strong>User Fee:</strong></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="annualFee"
                                            value={annualFee}  // Change from defaultValue to value
                                            disabled={changeType === "noChange"}
                                            ref={annualFee_ref}
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Stakeholder Count Section - Only show if stakeholderAllowed is true */}
                                {stakeholderAllowed && (
                                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="mb-3" style={{ flex: 1 }}>
                                            <label htmlFor="stakeholderCount" className="form-label"><strong>Stakeholder Count:</strong></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="stakeholderCount"
                                                value={stakeholderCount_Invoice || 0}
                                                disabled={changeType === "noChange"}
                                                onChange={(e) => {
                                                    // Only allow numbers
                                                    const value = e.target.value.replace(/\D/g, '');
                                                    const newStakeholderCount = Number(value);
                                                    
                                                    // Calculate new stakeholder fee using the constant perStakeholderFee
                                                    const newStakeholderFee = perStakeholderFee * newStakeholderCount;
                                                    
                                                    // Update states
                                                    setStakeholderCount_Invoice(newStakeholderCount);
                                                    setStakeholderFee(Math.round(newStakeholderFee).toString());
                                                    
                                                    // Calculate total fee
                                                    const totalFee = Number(annualFee) + 
                                                        Math.round(newStakeholderFee) + 
                                                        (callRoutingAllowed ? Number(editableCallRoutingFee) : 0);
                                                    
                                                    setTotalAnnualFee(totalFee);
                                                }}
                                                onBlur={(e) => {
                                                    const currentValue = Number(e.target.value);
                                                    
                                                    if (changeType === "upgradeUsers" && currentValue < initialStakeholderCount) {
                                                        e.target.setCustomValidity("For Upgrade Users, stakeholder count cannot be less than the original count");
                                                        e.target.reportValidity();
                                                    } else if (changeType === "downgradeUsers" && currentValue > initialStakeholderCount) {
                                                        e.target.setCustomValidity("For Downgrade Users, stakeholder count cannot be more than the original count");
                                                        e.target.reportValidity();
                                                    } else {
                                                        e.target.setCustomValidity("");
                                                    }
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="stakeholderFee" className="form-label"><strong>Stakeholder Fee:</strong></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="stakeholderFee"
                                                value={stakeholderFee || stakeholderFee_invoice || 0}  // Change from defaultValue to value
                                                disabled={changeType === "noChange"}
                                                ref={stakeholderFee_ref}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Call Routing Fee Section - Only show if callRoutingAllowed is true */}
                                {callRoutingAllowed && (
                                    <div className="form-row mb-3">
                                        <div className="mb-3" style={{ flex: 1 }}>
                                            <label htmlFor="callRoutingFee" className="form-label">
                                                <strong>Call Routing Fee:</strong>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="callRoutingFee"
                                                defaultValue={callRoutingFee} // Use defaultValue instead of value
                                                onChange={(e) => {
                                                    const newCallRoutingFee = e.target.value;
                                                    setEditableCallRoutingFee(newCallRoutingFee);
                                                    // For Renew Subscription modal
                                                    updateTotalFee(annualFee, stakeholderFee_invoice, newCallRoutingFee);
                                                    // For Add Additional Users modal
                                                    updateAdditionalUsersTotalFee(annualFee, stakeholderFee_invoice, newCallRoutingFee);
                                                }}
                                                required={callRoutingAllowed}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Comments Section */}
                                <div className="mb-3">
                                    <label htmlFor="comments" className="form-label"><strong>Comments/Reason:</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="comments"
                                        value={comments}
                                        ref={comments_ref}
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </div>
                                <br />

                                <div className="mb-3">
                                    <label className="form-label">
                                        <strong>Total Annual Fee: </strong>${totalAnnualFee}
                                    </label>
                                </div>

                                <button type="submit" className="btn btn-primary">Renew</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal
                        ref={modalRef_AddUsers}
                        show={showModalAddUsers}
                        onHide={handleCloseModal_forAddUsers}
                        animation={false}
                        >
                        <Modal.Header closeButton>
                            <Modal.Title>Add Additional Users</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form ref={formRef} onSubmit={handleAddUsers}>
                                <div className="form-row mb-3">
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label className="form-label">
                                            <strong>Package: </strong>{subscriptionPackageName}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label className="form-label">
                                            <strong>Existing User Fee: </strong>${userFee}
                                        </label>
                                    </div>
                                    {stakeholderAllowed && (<div className="mb-3" style={{ flex: 1 }}>
                                        <label className="form-label">
                                            <strong>Existing Stakeholder Fee: </strong>${stakeholderFee_invoice || 0}
                                        </label>
                                    </div>
                                    )}
                                </div>
                                

                                {/* User Count Section */}
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label htmlFor="existingUserCount" className="form-label">
                                            <strong>Existing User Count:</strong>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="existingUserCount"
                                            value={userCount_Invoice}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                        <label htmlFor="additionalUsers" className="form-label">
                                            <strong>Additional Users:</strong>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="additionalUsers"
                                            min={0}
                                            value={additionalUsers}
                                            onChange={(e) => {
                                                const newAdditionalUsers = Number(e.target.value) || 0;
                                                setAdditionalUsers(newAdditionalUsers);
                                                
                                                // Calculate and update the user fee
                                                const newUserFee = calculateUserFee(newAdditionalUsers);
                                                setannualFee(newUserFee.toString());
                                                
                                                // Update total fee
                                                updateAdditionalUsersTotalFee(
                                                    newUserFee, 
                                                    stakeholderFee_invoice, 
                                                    editableCallRoutingFee
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3" style={{ flex: 1 }}>
                                        <label htmlFor="totalUsers" className="form-label">
                                            <strong>Total User Count:</strong>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="totalUsers"
                                            value={userCount_Invoice + additionalUsers}
                                            disabled
                                        />
                                    </div>
                                </div>

                                {/* Annual Fee */}
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="mb-3" style={{ flex: 1 }}>
                                        <label htmlFor="annualFee" className="form-label">
                                            <strong>User Fee:</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="annualFee"
                                            value={annualFee}
                                            ref={annualFee_ref}
                                            onChange={(e) => {
                                                const newUserFee = e.target.value;
                                                setannualFee(newUserFee);
                                                updateAdditionalUsersTotalFee(newUserFee, stakeholderFee_invoice, editableCallRoutingFee);
                                            }}
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Stakeholder Section - Only show if stakeholderAllowed is true */}
                                {stakeholderAllowed && (
                                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                            <label htmlFor="existingStakeholders" className="form-label">
                                                <strong>Existing Stakeholder Count:</strong>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="existingStakeholders"
                                                value={stakeholderCount_Invoice || 0}
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                            <label htmlFor="additionalStakeholders" className="form-label">
                                                <strong>Additional Stakeholders:</strong>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="additionalStakeholders"
                                                min={0}
                                                value={additionalStakeholders}
                                                onChange={(e) => {
                                                    const newAdditionalStakeholders = Number(e.target.value) || 0;
                                                    setAdditionalStakeholders(newAdditionalStakeholders);
                                                    
                                                    // Calculate and update the stakeholder fee
                                                    const newStakeholderFee = calculateStakeholderFee(newAdditionalStakeholders);
                                                    setStakeholderFee(newStakeholderFee.toString());
                                                    
                                                    // Update total fee
                                                    updateAdditionalUsersTotalFee(
                                                        annualFee,
                                                        newStakeholderFee,
                                                        editableCallRoutingFee
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3" style={{ flex: 1 }}>
                                            <label htmlFor="totalStakeholders" className="form-label">
                                                <strong>Total Stakeholder Count:</strong>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="totalStakeholders"
                                                value={stakeholderCount_Invoice + additionalStakeholders}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Stakeholder Fee - Only show if stakeholderAllowed is true */}
                                {stakeholderAllowed && (
                                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="mb-3" style={{ flex: 1 }}>
                                            <label htmlFor="stakeholderFee" className="form-label">
                                                <strong>Stakeholder Fee:</strong>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="stakeholderFee"
                                                value={stakeholderFee || stakeholderFee_invoice || 0} // Use value instead of defaultValue
                                                ref={stakeholderFee_ref}
                                                onChange={(e) => {
                                                    const newStakeholderFee = e.target.value;
                                                    setStakeholderFee(newStakeholderFee);
                                                    updateAdditionalUsersTotalFee(
                                                        annualFee,
                                                        newStakeholderFee,
                                                        editableCallRoutingFee
                                                    );
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Call Routing Fee Section - Only show if callRoutingAllowed is true */}
                                {callRoutingAllowed && (
                                    <div style={{ display: 'none' }}>  {/* Hide the input but keep it functional */}
                                        <input
                                            type="number"
                                            id="callRoutingFee"
                                            defaultValue={callRoutingFee}
                                            onChange={(e) => {
                                                const newCallRoutingFee = e.target.value;
                                                setEditableCallRoutingFee(newCallRoutingFee);
                                                // For Renew Subscription modal
                                                updateTotalFee(annualFee, stakeholderFee_invoice, newCallRoutingFee);
                                                // For Add Additional Users modal
                                                updateAdditionalUsersTotalFee(annualFee, stakeholderFee_invoice, newCallRoutingFee);
                                            }}
                                            required={callRoutingAllowed}
                                        />
                                    </div>
                                )}

                                {/* Effective Date */}
                                <div className="ed" id="eDate">
                                    &#128197;
                                    <DatePicker
                                        id="subscriptionStartDate"
                                        selected={selectedDate_I}
                                        onChange={(date) => setSelectedDate_I(date)}
                                        minDate={new Date()}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Effective Date"
                                        required={true}  // Add this line
                                    />
                                </div>
                                <br />

                                {/* Comments Section */}
                                <div className="mb-3">
                                    <label htmlFor="comments" className="form-label">
                                        <strong>Comments/Reason:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="comments"
                                        value={comments}
                                        ref={comments_ref}
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </div>
                                <br />

                                <div className="mb-3">
                                    <label className="form-label">
                                        <strong>Total Annual Fee: </strong>${additionalUsersTotalFee}
                                    </label>
                                </div>

                                <button type="submit" className="btn btn-primary">
                                    Add Additional Users
                                </button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <ToastContainer className="toastMessage" />
                    <div className="overlay" id="dialog-container">
                        <div className="popup">
                            <p className="lcrMess" id="lMess">Live Call Routing Enabled Successfully!...Page will reload automatically...</p>
                            <p className="lcrFailMess" id="lFailMess">Enabling Live Call Routing was unsuccessful!...Page will reload automatically...</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
